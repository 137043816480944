import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlConstants } from '@shared/configurations/url-constants';
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class AdminLogService {

  constructor(private http: HttpClient,
    private configService: ConfigService) { 
  }
  
  getAdminLog(adminInfo : any ): Observable<any> {
    return this.http.post(urlConstants.ADMIN_LOG, adminInfo, this.configService.getAddEditHeaders());

  }

  getAdminData(adminInfo : any) : Observable<any> {
    return this.http.post(urlConstants.ADMIN_LOG,adminInfo,this.configService.getAddEditHeaders());
  }

  updateAdminLoginReuestStatus(req : any) : Observable<any> {
    return this.http.post(urlConstants.UPDATE_ADMIN_LOGIN_REQUEST,req,this.configService.getAddEditHeaders());
  }
}
