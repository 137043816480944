
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, map,tap } from 'rxjs/operators';
import { LoginService } from '../login.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { appLevelConstants } from '../shared/configurations/app-level-constants';
import { redirectUrlConstants, urlConstants } from '../shared/configurations/url-constants';
import { FirebaseService } from './firebase.service';

declare var myExtObject: any;
declare var webGlObject: any

@Injectable({
    providedIn: 'root'
  })
export class AccessInterceptor implements HttpInterceptor{
    constructor(private router : Router,
                private toaster: ToastrService,
                private loginService : LoginService,
                private firebaseService: FirebaseService,
                )
    {
      webGlObject.init();
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        
        if(req.url.includes(urlConstants.LOGOUT_API))
        { 
          
          return next.handle(req)
        }else
        {
          
          return  next.handle(req).pipe(
            
            tap(
                {
                    next: (response : HttpEvent<any> )=>
                    {   
                          if(response instanceof HttpResponse)
                            {
                               
                                if( response.body.status===403)
                            {   
                                
                                  let req = {
                                    refresh_token: localStorage.getItem('refresh_token'),
                                    role_type: localStorage.getItem('role_type'),
                                    user_id: localStorage.getItem('user_id'),
                                    action: appLevelConstants.LOGOUT_ACTION,
                                    module_name: appLevelConstants.LOGOUT_MODULE_NAME
                                  };
                                   this.firebaseService.createEvent('User Logout', { 
                                    user_name: localStorage.getItem('user_id'),
                                    user_role:  localStorage.getItem('user_id')
                                  });
                                  this.loginService.userLogout(req).subscribe(
                                    (response: any) => {
                                      
                                      return
                                    }
                                  );

                                  localStorage.clear();
                                  Cookie.deleteAll();  
                                  this.router.navigateByUrl(redirectUrlConstants.REDIRECT_LOGIN);
                            }
                        }
                        
                        return
                    },

                    error : (error)=>{
                      console.log("error",error)
                    }
                }
            
        ));
        
        }
     }
        
  }
