import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlConstants } from '@shared/configurations/url-constants';
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class AdminRequestService {

  constructor(private http: HttpClient,
    private configService: ConfigService) {
  }

  getAdminRequest(adminInfo: any): Observable<any> {
    return this.http.post(urlConstants.ADMIN_REQUEST, adminInfo, this.configService.getAddEditHeaders());

  }
}
