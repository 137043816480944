import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabViewModule } from 'primeng/tabview';
import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { AgmOverlays } from "agm-overlays";
import { AgmDirectionModule } from "agm-direction";
import { UserIdleModule } from "angular-user-idle"
//npm uninstall --save-dev angular-cli
//npm uninstall --save-dev plyr ngx-plyr

// form elements
import { SliderModule } from 'primeng/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';

import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { environment } from 'src/environments/environment';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MultiSelectModule } from 'primeng/multiselect';
import { LoginService } from './login.service';

import { GaugesModule } from 'ng-canvas-gauges';
import { MainHeaderComponent } from './shared/main-header/main-header.component';
import { LoginComponent } from './Components/login/login.component';






import { ClientListComponent } from './Components/client-list/client-list.component';
import { ClientApiComponent } from './Components/client-api/client-api.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { UserProfileComponent } from './Components/user-profile/user-profile.component';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { ToastModule } from 'primeng/toast';
import { ToastrModule } from 'ngx-toastr';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';



import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';



import { AccessInterceptor } from './services/access.interceptor';
import { SharedModule } from './shared/shared.module';
import { HeaderComponent } from '@shared/header/header.component';
import { AdminLogComponent } from './Components/admin-log/admin-log.component';
import { HandleShortLinksComponent } from './Components/handle-short-links/handle-short-links.component';
import { MatProgressBarModule } from '@angular/material';
import { AdminRequestComponent } from './Components/admin-request/admin-request.component';
import { AdminAccessComponent } from './Components/admin-access/admin-access.component';
import { PushNotificationComponent } from './Components/push-notification/push-notification.component';
import { BlockedIdManagementComponent } from './Components/blocked-id-management/blocked-id-management.component';
import { VehicleMasterCrnComponent } from './Components/vehicle-master-crn/vehicle-master-crn.component';
import { VehicleOnBoardingComponent } from './Components/vehicle-on-boarding/vehicle-on-boarding/vehicle-on-boarding.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FuelTrendComponent } from './fuel-trend/fuel-trend.component';
import {RatingModule} from 'primeng/rating';
import { ModifiedUrlInterceptor } from './services/modified-url.interceptor';

import * as firebase from 'firebase/app'
firebase.initializeApp(environment.firebaseConfig);
// import { UserDetailsComponent } from './Components/user-details/user-details.component';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  slidesPerView: 1,
  a11y: true
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,


    LoginComponent,



    ClientListComponent,
    ClientApiComponent,
    UserProfileComponent,
    MainHeaderComponent,

    HeaderComponent,

    AdminLogComponent,

    HandleShortLinksComponent,

    AdminRequestComponent,

    AdminAccessComponent,
    PushNotificationComponent,
    BlockedIdManagementComponent,
    VehicleMasterCrnComponent,
    VehicleOnBoardingComponent,
    FuelTrendComponent,


    // UserDetailsComponent
  ],
  imports: [
    //could remove some of these imports as they are present in shared module
    BrowserModule,
    HttpClientModule,
    SharedModule,
    SwiperModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    TabViewModule,
    FormsModule,
    InputTextModule,
    AutoCompleteModule,
    GaugesModule,
    UserIdleModule.forRoot({ idle: 3600, timeout: 2 }),
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    DropdownModule,
    NgApexchartsModule,
    FileUploadModule,
    AccordionModule,
    CheckboxModule,
    DialogModule,
    SelectButtonModule,
    InputSwitchModule,
    RadioButtonModule,
    SliderModule,
    MatSlideToggleModule,
    MultiSelectModule,
    TableModule,
    PaginatorModule,
    CheckboxModule,
    NgxSpinnerModule,

    ConfirmDialogModule,
    ReactiveFormsModule,
    ToastModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right',
    }),

    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAPS_API_KEY,
      libraries: ['places', 'drawing', 'geometry']
    }),
    AgmOverlays,
    AgmDirectionModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    NgxDaterangepickerMd.forRoot(),
    MatProgressBarModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ModifiedUrlInterceptor,
      multi: true
    },
    LoginService, ConfirmationService, GoogleMapsAPIWrapper ,
    TitleCasePipe, DatePipe
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

}
