import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { checkPattern } from '@shared/configurations/pattern-constants';
import { AdminAccessService } from 'src/app/services/admin-access.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-admin-access',
  templateUrl: './admin-access.component.html',
  styleUrls: ['./admin-access.component.scss']
})
export class AdminAccessComponent implements OnInit {
  adminlogQuery: FormGroup;
  tomodify: any = {
    "admin_email_id": "",
    "updated_config": [{
      "feature_name": "",
      "enable_access_api_index": []
    }]
  };
  updated_config: any = {};
  submitted: any;
  showAccessStatus: boolean = false;
  adminAccessData: any[];
  to_add_to_Index = [];
  enableAllArray = [];
  reqObj2: any = {
    "admin_email_id": [],
  }


  heading = [
    {
      name: "API name",
      value: "api_name",
      isAscBlue: false,
      isDescBlue: false,
    },
    {
      name: "Status",
      value: "status",
      isAscBlue: false,
      isDescBlue: false,
    }]



  constructor(
    private formBuilder: FormBuilder,
    private adminaccessservice: AdminAccessService,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
    this.adminlogQuery = this.formBuilder.group({
      "adminId": ['', [Validators.email,]],
    })
  }
  public showLoading = false;
  isAdminFormInValid = false;
  checkPattern = checkPattern;

  get fc() {
    return this.adminlogQuery.controls;
  }
  get fv() {
    return this.adminlogQuery.value;
  }


  fetchData() {
    this.checkAdminFormValidation(this.fc.adminId.value)
    this.submitted = true;
    if (this.isAdminFormInValid) {
      return;
    }
    else {
      this.showLoading = true;
      this.submitted = true;
      this.reqObj2 = {
        "admin_email_id": [this.fc.adminId.value.toLowerCase()],
      }

      this.adminaccessservice.getAdminAccessStatus(this.reqObj2).subscribe((response: any) => {

        if (response.status === 0 && response.hasOwnProperty("result") === true) {
          if (response.result.length <= 0) {
      this.showLoading = false;

            this.showAccessStatus = false;
            this.toaster.error("No record found", '', {
              timeOut: 5000
            });
          } else {
      this.showLoading = false;

            this.adminAccessData = response.result;
            // this.totalRecords = response.total_count;
            this.showAccessStatus = true;
            this.toaster.success(response.message, '', {
              timeOut: 5000
            });
          }
        } else {
      this.showLoading = false;

          this.showAccessStatus = false;
          this.toaster.error(response.message, '', {
            timeOut: 5000
          });
        }
      })
    }
  }

  checkAdminFormValidation(value: string) {
    if (this.checkPattern.EMAIL_VALIDATION.test(value)) {
      this.isAdminFormInValid = false
    }
    else {
      this.isAdminFormInValid = true
    }
  }

  accessmodify(feature_name, apilist) {
    this.to_add_to_Index = [];
    this.showLoading = true;

    for (var temp of apilist.api_list) {
      if (temp.access_for[0]) {
        this.to_add_to_Index.push(temp.index)
      }
    }

    this.tomodify = {
      "admin_email_id": this.fc.adminId.value.toLowerCase(),
      "updated_config": [{
        "feature_name": feature_name,
        "enable_access_api_index": this.to_add_to_Index
      }]
    }
    this.adminaccessservice.setAdminAccessStatus(this.tomodify).subscribe((response: any) => {
      if (response.status != 0) {
      this.showLoading = false;

        this.showAccessStatus=false
      }
      else{
      this.showLoading = false;

        this.showAccessStatus=true
      }
    })

  }

  enableAll(apilist, index) {
    this.enableAllArray = [...Array(apilist.api_list.length).keys()]
    this.tomodify = {
      "admin_email_id": this.fc.adminId.value.toLowerCase(),
      "updated_config": [{
        "feature_name": apilist.feature_name,
        "enable_access_api_index": this.enableAllArray
      }]
    };
    this.showLoading = true;

    this.adminaccessservice.setAdminAccessStatus(this.tomodify).subscribe((response: any) => {
      if (response.status === 0) {
      this.showLoading = false;

        this.adminAccessData[index].api_list.forEach(apilist => {
          this.showAccessStatus=true
          apilist.access_for[0] = true
        })
      }
      else{
      this.showLoading = false;

        this.showAccessStatus=false
      }
    })
  }

  disableAll(apilist, index) {
    this.tomodify = {
      "admin_email_id": this.fc.adminId.value.toLowerCase(),
      "updated_config": [{
        "feature_name": apilist.feature_name,
        "enable_access_api_index": []
      }]
    };
    this.showLoading = true;

    this.adminaccessservice.setAdminAccessStatus(this.tomodify).subscribe((response: any) => {
      if (response.status === 0) {
      this.showLoading = false;

        this.adminAccessData[index].api_list.forEach(apilist => {
          this.showAccessStatus=true
          apilist.access_for[0] = false
        })
      }
      else{
      this.showLoading = false;

        this.showAccessStatus=false
      }
    })
  }
}
