import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminLogService } from 'src/app/services/admin-log.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common'

@Component({
  selector: 'app-handle-short-links',
  templateUrl: './handle-short-links.component.html',
  styleUrls: ['./handle-short-links.component.scss']
})
export class HandleShortLinksComponent implements OnInit {

  constructor(private router: Router,
    private adminlogservice: AdminLogService,
    private location: Location
  ) { }

  ngOnInit() {
    let split_url = this.router.url.split("?")
    if (split_url && split_url.length > 1) {
      this.adminlogservice.updateAdminLoginReuestStatus({
        'encrypted_ref_no': split_url[1]
      }).subscribe((response: any) => {
        if (response && response.status === 0 && response.message) {
          Swal.fire({
            icon: 'info',
            title: response.message,
            showConfirmButton: true,
          }).then((result) => {
            this.navigateToHome()
          })
        }
        else {
          Swal.fire('Page not found').then((result) => this.navigateToHome())
        }
      })
    }
    else {
      Swal.fire('Page not found').then((result) => this.navigateToHome())
    }
  }

  navigateToHome() {
    if (localStorage.getItem('loggedInUserInfo')) {
      window.location.replace('/#/userProfile')
    }
    else {
      localStorage.clear()
      window.location.replace('')
    }
  }
}
