import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class RoleCheckGuard implements CanActivate, CanActivateChild {

  constructor(private configService : ConfigService)
  {
  }



  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let permissionList = next.data.permission
    let userInfo = this.configService.getLoggedInUserInfo();
    if (permissionList.includes(userInfo.role_type))
    {
      return true;
    }else{
      console.log("permission allowed for ",permissionList,"permission found ",userInfo.role_type)
      return false;
    }
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let permissionList = next.data.permission
      let userInfo = this.configService.getLoggedInUserInfo();
      if (permissionList.includes(userInfo.role_type))
      {
        return true;
      }else{
        console.log("permission allowed for ",permissionList,"permission found ",userInfo.role_type)
        return false;
      }
  }
  
}
