import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlConstants } from '@shared/configurations/url-constants';
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class BlockedIdManagementService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) { }

  getUserStatus(data: any): Observable<any> {
    return this.http.post(urlConstants.CHECK_BLOCKED_FLEET_ID_STATUS, JSON.stringify(data), this.configService.getAddEditHeaders())
  }

  setUserStatusToUnblock(data: any): Observable<any> {
    return this.http.post(urlConstants.UNBLOCK_FLEET_ID_STATUS, JSON.stringify(data), this.configService.getAddEditHeaders())
  }

}
