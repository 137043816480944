import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import * as firebase from 'firebase/app';
import { __param } from 'tslib';
import 'firebase/analytics';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  
  constructor(private configService: ConfigService) { }

  userInfo: any;

  createEvent(...args) {
    firebase.analytics().logEvent(args[0], args[1]);
  }
}
