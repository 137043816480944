import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
// import { PlyrComponent } from 'ngx-plyr';
import { LoginService } from 'src/app/login.service';
// import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { appLevelConstants } from '../configurations/app-level-constants';
import { ConfigService } from 'src/app/config.service';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { redirectUrlConstants } from '../configurations/url-constants';
import { Router } from '@angular/router';
import { TM_LOGO,USER_PLACEHODER } from '@shared/configurations/image-constants';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})


export class HeaderComponent implements OnInit {
  response: any;
  user_id: any;
  TM_LOGO = TM_LOGO;
  USER_PLACEHODER = USER_PLACEHODER;
// fullName = localStorage.getItem('loggedInUserInfo.full_name');
  userInfo = this.configService.getLoggedInUserInfo();

  // role = localStorage.getItem('role');
  isUserRoleAdmin: boolean = false;
  adminCount: any;
  tmlInternalCount: any;
  supportCount: any;
  showPopUp: boolean;

  constructor(private confirmationService: ConfirmationService, public ls: LoginService, private configService: ConfigService, private router: Router) {

    // tslint:disable-next-line:variable-name
    const dse_name = localStorage.getItem('username');
    // alert(str)
    this.dse_name = dse_name;
    // const token = localStorage.getItem('auth_token');

    // if (token !== undefined && token !== '') {
      // this.loggedInnUser=false;

    // }

  }
  get ro() {
    // tslint:disable-next-line:whitespace
    return this.response;
  }
  // }
  // userProfileService: any;
  // username = localStorage.getItem('username');
  // username = this.configService.getLoggedInUserInfo();
  reqObj: any;
  // username = this.userName;

  dse_name = '';
  loggedInnUser = true;

  public showMoreLink = false;
  public fleetMasterLinks = false;
  public notifiyDD = false;
  public userProfileDD = false;
  public sosNotifyDD = false;
  public showOverlay = false;
  public show = false;
  public isdisabled = false;

  userOnboardLabel = appLevelConstants.USER_ONBOARD_LABEL;
  eventDataLabel = appLevelConstants.EVENT_DATA_LABEL;
  telemetryDataLabel = appLevelConstants.TELEMETRY_DATA_LABEL;
  vehicleCanDataLabel = appLevelConstants.VEHICLE_CAN_DATA_LABEL;
  fuelAndOdometerDataLabel = appLevelConstants.FUEL_AND_ODOMETER_DATA_LABEL;
  ignitionStatusLabel = appLevelConstants.IGNITION_STATUS_LABEL;

  yes = appLevelConstants.YES;
  no = appLevelConstants.NO;

  // @ViewChild('plyr',{read:PlyrComponent,static:false})plyrComp:PlyrComponent;
  // @ViewChild( { static: false })
  // plyr: PlyrComponent;
  // PlyrComponent,
  options = {
    autoplay: false
  };

  // plyr: PlyrComponent;

  // player: Plyr;

  audioSources = [
    {
      src: 'assets/sos-msg/sample-audio.mp3',
      type: 'audio/mp3',
    }
  ];

  audioSources1 = [
    {
      src: 'https://cdn.plyr.io/static/demo/Kishi_Bashi_-_It_All_Began_With_a_Burst.mp3',
      type: 'audio/ogg',
    },
  ];

  mobileDevice = false;

  public citiesDD = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' }
  ];


  cities = [
    { name: 'New York', code: 'NY' },
    { name: 'Rome', code: 'RM' },
    { name: 'London', code: 'LDN' },
    { name: 'Istanbul', code: 'IST' },
    { name: 'Paris', code: 'PRS' }
  ];

  // selectedCity: City;

  // qty section START

  qty = 0;

  // qty section END





  rangeVal = 0;

  // auto-complete dd

  locations: string[] = ['mumbai', 'delhi', 'Pune'];

  filteredLocations: any[];
  location: string;



  // from - to location swap START
  fromLocations: string[] = ['mumbai', 'delhi', 'Pune'];

  filteredfromLocations: any[];
  fromLocation: string;

  toLocations: string[] = ['mumbai', 'delhi', 'Pune'];

  filteredtoLocations: any[];
  toLocation: string;
  // from - to location swap END


  // range date selection - type 2 - START

  fromDateVal: Date;
  toDateVal: Date;
  minDate = new Date();
  // range date selection - type 2 - END

  // Popup start

  showPopup = false;



  ngOnInit() {
    if (this.userInfo.role_type === 'Admin') {
      this.isUserRoleAdmin = true;
    }

    const token = localStorage.getItem('auth_token');

    if (token !== undefined && token !== '') {
      this.loggedInnUser = true;
    }

    // perfect scrollbar mobile disable
    const wd = window.innerWidth;
    if (wd < 1170) {
      this.isdisabled = true;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 52) {
      document.querySelector('.cp-sidebar').classList.add('sticky-nav');
      document.querySelector('body').classList.add('sticky-nav');
    } else {
      document.querySelector('.cp-sidebar').classList.remove('sticky-nav');
      document.querySelector('body').classList.remove('sticky-nav');
    }
  }

  toggleMoreLink(event) {
    event.stopPropagation();
    if (this.showMoreLink) {
      this.showMoreLink = false;
      document.querySelector('body').classList.remove('overflow-hidden');
    } else {
      document.querySelector('body').classList.add('overflow-hidden');
      this.showMoreLink = true;
    }
  }

  toggleLinks() {
    this.fleetMasterLinks = !this.fleetMasterLinks;
  }

  toggleNotify(event) {
    event.stopPropagation();
    this.showMoreLink = false;
    this.userProfileDD = false;
    this.sosNotifyDD = false;
    if (this.notifiyDD) {
      this.notifiyDD = false;
      this.showOverlay = false;
    } else {
      this.notifiyDD = true;
      this.showOverlay = true;
    }
  }

  toggleSosNotify(event) {
    event.stopPropagation();
    this.showMoreLink = false;
    this.userProfileDD = false;
    this.notifiyDD = false;
    if (this.sosNotifyDD) {
      this.sosNotifyDD = false;
      this.showOverlay = false;
    } else {
      this.sosNotifyDD = true;
      this.showOverlay = true;
    }
  }

  toggleUserProfile(event) {
    event.stopPropagation();
    this.sosNotifyDD = false;
    this.showMoreLink = false;
    this.notifiyDD = false;
    if (this.userProfileDD) {
      this.userProfileDD = false;
      this.showOverlay = false;
    } else {
      this.userProfileDD = true;
      this.showOverlay = true;
    }
  }

  overlayClick(event) {
    event.stopPropagation();
    this.sosNotifyDD = false;
    this.userProfileDD = false;
    this.notifiyDD = false;
    this.showOverlay = false;
  }

  incrementQty() {
    this.qty += 1;

  }
  decrementQty() {
    if (this.qty > 0) {
      this.qty -= 1;
    }
  }

  filterLocations(event) {
    this.filteredLocations = [];
    for (let i = 0; i < this.locations.length; i++) {
      const location = this.locations[i];
      if (location.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
        this.filteredLocations.push(location);
      }
    }
  }

  filterfromLocations(event) {
    this.filteredfromLocations = [];
    for (let i = 0; i < this.fromLocations.length; i++) {
      const fromLocation = this.fromLocations[i];
      if (fromLocation.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
        this.filteredfromLocations.push(fromLocation);
      }
    }
  }

  filtertoLocations(event) {
    this.filteredtoLocations = [];
    for (let i = 0; i < this.toLocations.length; i++) {
      const toLocation = this.toLocations[i];
      if (toLocation.toLowerCase().indexOf(event.query.toLowerCase()) === 0) {
        this.filteredtoLocations.push(toLocation);
      }
    }
  }

  swapLocationFn() {
    const oldFromLocation = this.fromLocation;
    const oldToLocation = this.toLocation;
    this.toLocation = oldFromLocation;
    this.fromLocation = oldToLocation;
  }
  getFromDate() {
    const selectedFromDate = this.fromDateVal;
    this.minDate = selectedFromDate;
  }

  showDialog() {
    this.showPopup = true;
  }
  // Popup end

  onUpload(event) {
  }

  confirm() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to Log out?',
      accept: () => {
        this.logOut();
      }
    });
  }
  isLoggedInUser() {
    this.loggedInnUser = true;
  }

  logOut() {
    localStorage.clear();
    sessionStorage.clear();
    Cookie.deleteAll();
    this.loggedInnUser = false;
    // window.location.href='/cvpanalytics/#/login';
    // window.location.href = '/';
    this.router.navigateByUrl(redirectUrlConstants.REDIRECT_LOGIN);
    window.location.reload()
  }


  openLogoutPopup(): void {
    this.showPopUp= true;
  }

}
