import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  constructor() { }
  // Initializing variable for EventEmitting
  @Input() totalRecords: number;
  @Output() paginationEvent = new EventEmitter();
  @Input() currentPage: number;
  // Initializing variables for processing.
  pageNumArr: Array<number>;
  // currentPage = 1;
  lastPage: any;

  // Initializing boolean and numbers needed for HTML.
  pageOption1: any;
  pageOption2: any;
  pageOption3: any;
  isActivePageOption1: boolean;
  isActivePageOption2: boolean;
  isActivePageOption3: boolean;
  isPresentPageOption1: boolean;
  isPresentPageOption2: boolean;
  isPresentPageOption3: boolean;
  isPresentGoToPrevious: boolean;
  isPresentGoToNext: boolean;
  firstRecordNum: number;
  lastRecordNum: number;

  ngOnInit() {
    // Initialize the page state on loading
    this.pageOption1 = 1;
    this.pageOption2 = 2;
    this.pageOption3 = 3;
    this.firstRecordNum = 1;
    this.isActivePageOption1 = true;
    this.isActivePageOption2 = false;
    this.isActivePageOption3 = false;
    this.currentPage = this.pageOption1;
    // this.isPresentGoToPrevious = true;
    // this.isPresentGoToNext = true;
  }

  // Route to previous page section
  goToPrevious() {
    if (this.currentPage > 3) {
      this.pageOption1 -= 3;
      this.pageOption2 -= 3;
      this.pageOption3 -= 3;
      this.isPresentPageOption1 = true;
      this.isPresentPageOption2 = true;
      this.isPresentPageOption3 = true;
      this.routeToOptionThree();
    }
  }

  // Route to page option 1
  routeToOptionOne() {
    this.isActivePageOption1 = true;
    this.isActivePageOption2 = false;
    this.isActivePageOption3 = false;
    this.currentPage = this.pageOption1;
    this.paginationEvent.emit(this.currentPage);
    this.checkPagePresence();
  }

  // Route to page option 2
  routeToOptionTwo() {
    this.isActivePageOption1 = false;
    this.isActivePageOption2 = true;
    this.isActivePageOption3 = false;
    this.currentPage = this.pageOption2;
    this.paginationEvent.emit(this.currentPage);
    this.checkPagePresence();
  }

  // Route to page option 3
  routeToOptionThree() {
    this.isActivePageOption1 = false;
    this.isActivePageOption2 = false;
    this.isActivePageOption3 = true;
    this.currentPage = this.pageOption3;
    this.paginationEvent.emit(this.currentPage);
    this.checkPagePresence();
  }

  // Route to next page section
  goToNext() {
    const lastPageSection = Math.ceil(this.lastPage / 3);
    const currentPageSection = Math.ceil(this.currentPage / 3);
    // Check if current page section in less than last page section
    if (currentPageSection < lastPageSection) {

      if (this.pageNumArr[this.pageOption1 + 2]) {
        this.isPresentPageOption1 = true;
      } else {
        this.isPresentPageOption1 = false;
      }

      if (this.pageNumArr[this.pageOption2 + 2]) {
        this.isPresentPageOption2 = true;
      } else {
        this.isPresentPageOption2 = false;
      }

      if (this.pageNumArr[this.pageOption3 + 2]) {
        this.isPresentPageOption3 = true;
      } else {
        this.isPresentPageOption3 = false;
      }

      this.pageOption1 += 3;
      this.pageOption2 += 3;
      this.pageOption3 += 3;
      this.routeToOptionOne();
    }
  }

  checkPagePresence() {
    // Handling presence of previous and next
    if (this.totalRecords < 10) {
      this.currentPage = 1;
      this.pageOption1 = 1;
      this.pageOption2 = 2;
      this.pageOption3 = 3;
      this.isActivePageOption1 = true;
      this.isActivePageOption2 = false;
      this.isActivePageOption3 = false;
    }
    if (this.currentPage <= 3) {
      this.isPresentGoToPrevious = false;
    } else {
      this.isPresentGoToPrevious = true;
    }
    const lastPageSection = Math.ceil(this.lastPage / 3);
    const currentPageSection = Math.ceil(this.currentPage / 3);
    // Check if current page section is equal to last page section
    if (currentPageSection === lastPageSection) {
      this.isPresentGoToNext = false;
    } else {
      this.isPresentGoToNext = true;
    }

    // For displaying page num

    this.firstRecordNum = this.currentPage * 10 - 9;
    this.lastRecordNum = this.currentPage * 10;
    if (this.lastRecordNum > this.totalRecords) {
      this.lastRecordNum = this.totalRecords;
    }
  }

  ngOnChanges(changes) {
    if (changes.totalRecords) {
      if (this.totalRecords !== undefined) {
        this.pageNumArr = [];
        this.lastPage = Math.ceil(this.totalRecords / 10);
        for (let index = 1; index <= this.lastPage; index++) {
          this.pageNumArr.push(index);
        }


        if (this.pageNumArr.length >= 1) {
          this.isPresentPageOption1 = true;
        } else {
          this.isPresentPageOption1 = false;
        }

        if (this.pageNumArr.length >= 2) {
          this.isPresentPageOption2 = true;
        } else {
          this.isPresentPageOption2 = false;
        }

        if (this.pageNumArr.length >= 3) {
          this.isPresentPageOption3 = true;
        } else {
          this.isPresentPageOption3 = false;
        }
        this.checkPagePresence();
      }
    }
    if (changes.currentPage) {
      if (this.currentPage === 1) {
        this.pageOption1 = 1;
        this.pageOption2 = 2;
        this.pageOption3 = 3;
        this.isActivePageOption1 = true;
        this.isActivePageOption2 = false;
        this.isActivePageOption3 = false;
        this.currentPage = this.pageOption1;
        this.checkPagePresence();
      }
    }
    if (this.totalRecords <= 10) {
      this.isPresentPageOption1 = false;
    } else {
      this.isPresentPageOption1 = true;
    }
  }
}