import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlConstants } from 'src/app/shared/configurations/url-constants';
import { ConfigService } from 'src/app/config.service';
@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  httpOptions = this.configService.getApplicationHeaders();
  addhttpOptions = this.configService.getAddEditHeaders();

  // API call for fetching USER ACTIVITY DETAILS
  getUserActivity(userData): Observable<any> {
    return this.http.post(urlConstants.USER_ACTIVITY_DETAIL, JSON.stringify(userData), this.getAddEditHeaders());
  }

  getKeyCloakToken() {
    return 'Bearer ' + localStorage.getItem('auth_token');
  }

  getAddEditHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: this.getKeyCloakToken()
      })
    };
  }
}
