import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlConstants } from 'src/app/shared/configurations/url-constants';
import { ConfigService } from 'src/app/config.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  public userBeingProcessed: any;
  public userProfileObj: any;


  constructor(private http: HttpClient,
    private configService: ConfigService) { }

  httpOptions = this.configService.getApplicationHeaders();
  addhttpOptions = this.configService.getAddEditHeaders();

  // API Call to get user details
  activeUserCount(userData): Observable<any> {
    return this.http.post(urlConstants.GET_ACTIVE_USERS, userData, this.configService.getAddEditHeaders());
  }

  // set user object
  setUserObject(userObject) {
    this.userBeingProcessed = userObject;
  }

  // get user object
  getUserObject() {
    return this.userBeingProcessed;
  }

  getKeyCloakToken() {
    return 'Bearer ' + localStorage.getItem('auth_token');
  }


  getAddEditHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: this.getKeyCloakToken()
      })
    };
  }
}
