import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { errorMessages } from 'src/app/shared/configurations/error-messages-constants';
import { checkPattern } from 'src/app/shared/configurations/pattern-constants';
import { VehicleListService } from 'src/app/services/vehicle-list.service';
import { VehicleCanDataService } from 'src/app/services/vehicle-can-data.service';
import { debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { urlConstants } from '@shared/configurations/url-constants';
import { TitleCasePipe, UpperCasePipe, DatePipe } from '@angular/common';
import { ConfigService } from 'src/app/config.service';
import * as moment from 'moment';
import { BULK_UPLOAD_SVG,GROUP_6 } from '@shared/configurations/image-constants';

@Component({
  selector: 'app-vehicle-on-boarding',
  templateUrl: './vehicle-on-boarding.component.html',
  styleUrls: ['./vehicle-on-boarding.component.scss'],
  providers: [TitleCasePipe, UpperCasePipe, DatePipe]
})
export class VehicleOnBoardingComponent implements OnInit {
  BULK_UPLOAD_SVG = BULK_UPLOAD_SVG;
  GROUP_6 = GROUP_6;
  HTML_DATA_VARIABLES = urlConstants.HTML_VARIABLES_BULK_UPLOAD;
  s3LinkCvp = urlConstants.s3Mvp1URL;
  errorMessages = errorMessages;
  make = 'Make';
  vehicleModel = 'Vehicle Model*';
  fleetId = 'User Name';
  vinNumber = 'Vin Number*';
  regNumber = 'Registration Number*';
  branchId = 'Branch Id*';
  check = 'Check';
  accountName = 'Account Name*';
  regDate = 'Registration Date*';
  engineNumber  = 'Engine Number';
  emitionNom = 'Emition Nom*';
  vehicleApplication  = 'Vehicle Application';
  fuelType = 'Fuel Type*';
  vehicleTypee = 'Vehicle Type*';
  vehicleType: any;
  communicationType = 'Communication Type';
  vehicleLob = 'Vehicle LOB*';
  bodyType = 'Body Type';
  tonnage = 'Tonnage';
  makeYearMonth = 'Make Year/Month';
  regAddress = 'Registration Address';
  corridor = 'Corridor';
  state = 'State';
  district = 'District';
  city = 'City';
  specification = 'Specification';
  noOfWheels = 'No of Wheels';
  ladenWeight = 'Laden Weight';
  unladenWeight = 'Unladen Weight';
  vehicleCustomCode = 'Vehicle Custom Code';
  addOns = 'Add Ons';
  ownedOutside = 'Owned Outside';
  vendorName = 'Vendor Name';
  vendorCompany  = 'Vendor Company';
  submit = 'Submit';
  filteredCorridorData: any;
  searchObject: {};
  selectedCorridor;
  corridorId: any;
  searchData: any;
  filterValue: any;
  addVehicleOnBoard: FormGroup;
  checkVehicleOnBoard: FormGroup;
  checkpattern = checkPattern;
  checked = false;
  submitted = false;
  isFuelTypeEmpty = false;
  emitionEmpty = false;
  reqObj: any;
  isCorrect = false;
  userId: any[];
  loggedInUserInfo: any;

  isFailedSheet = false;
  fileInput: any = false;
  templateLink: any;
  uploadFile: FormGroup;
  remainingMasterForm: FormGroup;
  jsonData: any;
  changedObj: any;
  jsonKeyData: any;
  worksheet: any;
  failedData: any;
  isDisabled = false;
  vehicleApplicationData: any;
  fuelData: any;
  vendorData: any;
  additionalField = urlConstants.VEHICLE_MASTER_ADDITIONAL_FIELD;
  deleteKeysOfReq = urlConstants.DELETE_KEYS_ADDITIONAL;
  vehicleMasterKeys = urlConstants.VEHICLE_MASTER_EXCEL_KEYS;
  exportKeysCommon = urlConstants.EXPORT_DELETE_KEYS_COMMON;
  reason = urlConstants.reason;

  getPage = {
    sort: '',
    field_name: '',
    search_text: '',
    fleet_id: ''
  };

  fuelTypeData = [
    { id: 1, fuel_type: 'Diesel' },
    { id: 2, fuel_type: 'CNG' },
    { id: 3, fuel_type: 'Petrol' },
    { id: 4, fuel_type: 'Electric' },
    { id: 5, fuel_type: 'Bifuel' },
    { id: 6, fuel_type: 'LNG' },
  ];

  emitionNomData = [
    { id: 1, emition_nome: 'BS III' },
    { id: 2, emition_nome: 'BS IV' },
    { id: 3, emition_nome: 'BS VI' },
  ];

  communicationData = [
    { id: 1, comm_type: 'Connected'},
    { id: 2, comm_type: 'Not connected'}
  ];

  vehicleTypeData = [
    { id: 1, vehicle_type: 'Tractor & Trailer'},
    { id: 2, vehicle_type: 'Truck'},
    { id: 3, vehicle_type: 'Tipper'},
    { id: 4, vehicle_type: 'Bus'},
    { id: 5, vehicle_type: 'SCV'},
    { id: 6, vehicle_type: 'SCV EV'},
  ];

  stateDropdown: any = ['NA'];
  districtDropdown: any = ['NA'];
  cityDropdown: any = ['NA'];
  selectedStateCode: any;
  dropdownEvents: any;
  selectedFleetid: any;
  fleetIdData: any;
  fleetidget: any;
  selectedFuelType: any;
  slelectedEmition: any;
  branchData: any;
  lobData: any;
  visible = false;
  branchIdValue = '';
  lobDataValue = '';
  lobIdData: any;
  userLob: any[];
  lobIdGet = '';
  uploadDisplay = false;
  isCorrectSheet = false;
  filename: any;
  fileSize: any;
  fileUploaded: any;
  lastModified: any;
  storeData: any;
  submittedexcel;
  excelData: any;
  showbulkicon;
  userInfo: any;
  currentDate: Date = new Date();
  constructor(
    public configService: ConfigService,
    private formBuilder: FormBuilder,
    private vehicleListService: VehicleListService,
    private vehicleCanDataService: VehicleCanDataService,
    private toaster: ToastrService,
  ) {
   }

  ngOnInit() {
    this.createForm();
    this.createBulkForm();
    this.getAddressStates();
    this.getAddressDistricts();
    this.getAddressCities();
    this.vehicleDetail();
    this.userInfo = this.configService.getLoggedInUserInfo();

    this.addVehicleOnBoard.get('branch_id').valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.branchIdValue = this.addVehicleOnBoard.value.branch_id;
      });

    this.addVehicleOnBoard.get('vehicleLob').valueChanges
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.lobDataValue = value;
      });

    this.checkVehicleOnBoard.get('fleetId').valueChanges
      .subscribe((value) => {
        this.selectedFleetid = value;
      });
  }

  vehicleDetail() {
    this.checked = true;
    this.userId = [];
    this.vehicleCanDataService.getUserId(this.reqObj).subscribe(response => {
    if (response) {
      if (response.status === 0 && response.hasOwnProperty('result') === true) {
        if (response.result.length <= 0) {
          this.toaster.error('User not found', '', {
            timeOut: 5000
          });
        } else {
          this.fleetIdData = response.result;
          const check = response.result;
          const arraycheck = Object.values(check);
          arraycheck.forEach(element => {
            this.userId.push({label: element, value: element});
          });
          this.dropdownEvents = this.userId;
        }
      } else {
        this.toaster.error(response.message, '', {
          timeOut: 5000
        });
      }
    }
  });
  }

  createForm() {
    this.checkVehicleOnBoard = this.formBuilder.group({
      fleetId: [''],
      vinNumber: ['', [Validators.required, Validators.pattern(this.checkpattern.VIN_VALIDATION)]],
    });
    this.addVehicleOnBoard = this.formBuilder.group({
      make: ['TML', [Validators.pattern(this.checkpattern.FULL_NAME)]],
      vehicleModel: ['', [Validators.required]],
      branch_id: ['', [Validators.required]],
      regDate: ['', [Validators.required]],
      regNumber: ['', [Validators.required, Validators.pattern(this.checkpattern.REG_VALIDATION)]],
      engineNumber: [''],
      emitionNom : ['', [Validators.required]],
      fuelType: ['', [Validators.required]],
      vehicleApplication: ['', [Validators.pattern(this.checkpattern.FULL_NAME)]],
      vehicleType: ['', [Validators.required]],
      communicationType: [''],
      vehicleLob: ['', [Validators.required]],
      account_name: ['', [Validators.required, Validators.pattern(this.checkpattern.FULL_NAME)]],
      bodyType: ['Tanker'],
      tonnage: ['', [Validators.pattern(this.checkpattern.NUMBER)]],
      makeYearMonth: [''],
      regAddress: ['', [Validators.pattern(this.checkpattern.FULL_NAME)]],
      corridorName: ['', [Validators.pattern(this.checkpattern.FULL_NAME)]],
      state: [''],
      district: [''],
      city: [''],
      specification: ['', [Validators.pattern(this.checkpattern.FULL_NAME)]],
      noWheels: [4, [Validators.pattern(this.checkpattern.NUMBER)]],
      ladenWeight: ['', [Validators.pattern(this.checkpattern.NUMBER)]],
      unladenWeight: ['', [Validators.pattern(this.checkpattern.NUMBER)]],
      vehicleCustomCode: [''],
      add_on: [''],
      ownedOutside: [''],
      vendorName: ['', [Validators.pattern(this.checkpattern.FULL_NAME)]],
      vendorCompany: ['', [Validators.pattern(this.checkpattern.FULL_NAME)]],
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.addVehicleOnBoard.invalid) {
      return;
    }
    const checkLob = this.lobIdData;
    checkLob.forEach(ele => {
      if (ele.vehicle_lob_name === this.lobDataValue) {
        this.lobIdGet = ele.vehicle_lob_id;
      }
    });

    let fuelTypeValue;
    let fuelKey;
    if (this.isFuelTypeEmpty === true) {
      fuelTypeValue = this.addVehicleOnBoard.value.fuelType.fuel_type;
      fuelTypeValue = Object.values(fuelTypeValue);
    } else {
      fuelTypeValue = this.addVehicleOnBoard.value.fuelType;
      fuelKey = Object.values(fuelTypeValue);
      this.selectedFuelType = fuelKey['1'];
    }

    let emitionValue;
    if (this.emitionEmpty === true) {
      emitionValue = this.addVehicleOnBoard.value.emitionNom.emition_nome;
      emitionValue = Object.values(emitionValue);
    } else {
      emitionValue = this.addVehicleOnBoard.value.emitionNom;
      emitionValue = Object.values(emitionValue);
      this.slelectedEmition = emitionValue['1'];
    }

    let communicationValue;
    if (this.addVehicleOnBoard.value.communicationType === 'connected') {
      communicationValue = true;
    } else {
      communicationValue = false;
    }

    let branchNameData;
    if (this.addVehicleOnBoard.value.branch_id) {
      branchNameData = this.addVehicleOnBoard.value.branch_id.branch_id;
    } else {
      branchNameData = '';
    }

    let lobNameData;
    if (this.addVehicleOnBoard.value.vehicleLob) {
      lobNameData = this.addVehicleOnBoard.value.vehicleLob.vehicleLob;
    } else {
      lobNameData = '';
    }

    this.reqObj = {
      auser_name: 'ispadmin@sankeysolutions.com',
      owner_id: this.fleetidget ? this.fleetidget : 'NA',
      vendor_name: this.addVehicleOnBoard.value.vendorName ? this.addVehicleOnBoard.value.vendorName : '',
      vendor_company: this.addVehicleOnBoard.value.vendorCompany ? this.addVehicleOnBoard.value.vendorCompany : '',
      vehicle_application: this.addVehicleOnBoard.value.vehicleApplication ? this.addVehicleOnBoard.value.vehicleApplication : '',
      vehicle_model: this.addVehicleOnBoard.value.vehicleModel ? this.addVehicleOnBoard.value.vehicleModel : 'NA',
      vehicle_model_id: '',
      vehicle_type: this.addVehicleOnBoard.value.vehicleType.vehicle_type ? this.addVehicleOnBoard.value.vehicleType.vehicle_type : 'NA',
      vins: this.checkVehicleOnBoard.value.vinNumber ? this.checkVehicleOnBoard.value.vinNumber : 'NA',
      registration_number: this.addVehicleOnBoard.value.regNumber ? this.addVehicleOnBoard.value.regNumber : 'NA',
      registration_date: this.addVehicleOnBoard.value.regDate ? moment(this.addVehicleOnBoard.value.regDate).format('YYYY-MM-DD') : null,
      fuel_type: this.selectedFuelType ? this.selectedFuelType : '',
      make: this.addVehicleOnBoard.value.make ? this.addVehicleOnBoard.value.make : 'NA',
      engine_number: this.addVehicleOnBoard.value.engineNumber ? this.addVehicleOnBoard.value.engineNumber : 'NA',
      emition_nom: this.slelectedEmition ? this.slelectedEmition : 'NA',
      owner_name: this.addVehicleOnBoard.value.owner_name ? this.addVehicleOnBoard.value.owner_name : 'NA',
      registration_address: this.addVehicleOnBoard.value.regAddress ? this.addVehicleOnBoard.value.regAddress : 'NA',
      profile_name: '',
      account_name: this.addVehicleOnBoard.value.account_name ? this.addVehicleOnBoard.value.account_name : 'NA',
      fleet_id: this.fleetidget ? this.fleetidget : 'NA',
      branch_id: branchNameData ? branchNameData : 'NA',
      corridor_id: this.addVehicleOnBoard.value.corridorName ? this.addVehicleOnBoard.value.corridorName : 'NA',
      body_type: this.addVehicleOnBoard.value.bodyType ? this.addVehicleOnBoard.value.bodyType : 'NA',
      tonnage: this.addVehicleOnBoard.value.tonnage ? this.addVehicleOnBoard.value.tonnage : 'NA',
      make_year_month: this.addVehicleOnBoard.value.makeYearMonth ?
        moment(this.addVehicleOnBoard.value.makeYearMonth).format('YYYY-MM-DD') : null,
      vehicle_profile_link: '',
      state: typeof this.addVehicleOnBoard.value.state === 'object' ?
        this.addVehicleOnBoard.value.state.name : this.addVehicleOnBoard.value.state,
      district: typeof this.addVehicleOnBoard.value.district === 'object' ?
        this.addVehicleOnBoard.value.district.name : this.addVehicleOnBoard.value.district,
      city: typeof this.addVehicleOnBoard.value.city === 'object' ?
        this.addVehicleOnBoard.value.city.name : this.addVehicleOnBoard.value.city,
      specification_type: this.addVehicleOnBoard.value.specification ? this.addVehicleOnBoard.value.specification : 'NA',
      number_of_wheels: this.addVehicleOnBoard.value.noWheels ? this.addVehicleOnBoard.value.noWheels : '',
      lob: this.lobIdGet ? this.lobIdGet : '',
      is_connected: communicationValue ? communicationValue : false,
      ownership_type: this.addVehicleOnBoard.value.ownedOutside ? this.addVehicleOnBoard.value.ownedOutside : '',
      laden_weight: this.addVehicleOnBoard.value.ladenWeight ? this.addVehicleOnBoard.value.ladenWeight : '',
      unladen_weight: this.addVehicleOnBoard.value.unladenWeight ? this.addVehicleOnBoard.value.unladenWeight : '',
      vehicle_custom_code: this.addVehicleOnBoard.value.vehicleCustomCode ? this.addVehicleOnBoard.value.vehicleCustomCode : '',
      add_on: this.addVehicleOnBoard.value.add_on ? this.addVehicleOnBoard.value.add_on : '',
      client_name: '',
      vehicle_document: {
            doc_type: {
                doc: '',
                docx: ''
            },
            doc_type_permit: {
                doc: '',
                docx: ''
            },
            doc_type_insurance: {
                doc: ''
            }
        },
        vehicle_due_data: {},
        updated_by: '',
        updated_at: '',
        created_at: '',
        created_by: '',
        is_deleted: false,
        vehicle_status: '',
        geography: 'NA',
        additional_fields: {
            tank_details: {
                tank_cert_no: '',
                tank_recp_no: '',
                tank_cert_per: '',
                tank_cert_vendor: '',
                tank_next_due_date: '',
                tank_cert_renewed_on: '',
                tank_cert_vendor_inv: ''
            },
            other_Details: {
                truck_sale: '',
                truck_scrap: '',
                own_out_side: ''
            },
            truck_details: {
                truck_type: '',
                make_year_month: '',
                truck_chassis_no: '',
                truck_load_capacity: ''
            },
            permit_details: [
                {
                    permit_amt: '',
                    permit_period: '',
                    permit_rec_no: '',
                    permit_due_date: '',
                    permit_selected: 'national',
                    permit_renewed_on: ''
                }
            ],
            fitness_details: {
                fitness_no: '',
                fitness_amount: '',
                fitness_period: '',
                fitness_due_date: '',
                fitness_renewed_on: ''
            },
            road_tax_details: [
                {
                    road_tax: '',
                    road_tax_amt: '',
                    road_tax_period: '',
                    tax_next_due_date: '',
                    road_tax_receipt_no: '',
                    road_tax_renewed_on: ''
                }
            ],
            green_tax_details: {
                green_tax: '',
                green_tax_amount: '',
                green_tax_period: '',
                green_tax_rec_no: '',
                green_tax_due_date: '',
                green_tax_renewed_on: ''
            },
            insurance_details: {
                insurance_co: '',
                premium_amount: '',
                insurance_due_date: '',
                insurance_policy_no: '',
                insurance_renew_date: ''
            },
            truck_body_details: {
                body_roi: '',
                body_tcs: '',
                body_cgst: '',
                body_igst: '',
                body_sgst: '',
                body_type: '',
                body_date_to: '',
                body_date_from: '',
                invoice_picker: '',
                body_finance_co: '',
                body_master_name: '',
                body_agreement_no: '',
                body_finance_type: '',
                body_invoice_date: '',
                body_invoice_value: '',
                body_discount_price: '',
                body_installment_amt: '',
                body_registration_changes: ''
            },
            truck_status_details: {
                to_date: '',
                from_date: '',
                truck_status: '',
                truck_at_depo: ''
            },
            truck_finance_details: {
                finance_co: '',
                finance_date_to: '',
                finance_date_from: '',
                truck_finance_type: '',
                finance_agreement_no: '',
                truck_installment_amt: '',
                truck_installment_date: ''
            },
            truck_invoice_details: {
                truck_tcs: '',
                truck_cgst: '',
                truck_igst: '',
                truck_sgst: '',
                truck_invoice_no: '',
                truck_dealer_name: '',
                truck_invoice_data: '',
                truck_at_discount_price: '',
                truck_registration_changes: ''
            },
            speed_governer_details: {
                speed_co_name: '',
                speed_seal_no: '',
                speed_governer: '',
                speed_serial_no: '',
                speed_gov_period: '',
                speed_renewed_date: '',
                speed_next_due_date: '',
                speed_certificate_no: ''
            },
            truck_compressor_details: {
                compressor: '',
                compressor_tcs: '',
                compressor_cgst: '',
                compressor_igst: '',
                compressor_sgst: '',
                compressor_invoice_no: '',
                compressor_reg_payment: '',
                compressor_invoice_date: '',
                compressor_invoice_value: '',
                compressor_discount_price: ''
            }
        },
        approval_status: '',
        rejected_reason: '',
        rc_book_document: {
            document: []
        },
        is_verified: false,
        arn: '',
        first_sale_date: '',
        crn: '',
        veh_model: '',
        pl: '',
        maximum_loading_capacity: 0.0,
        vehicle_sg_code: '',
        client_id: '',
        bu: '',
        other_document: {
            otherDocumentList: []
        },
        offboard_date: '',
        offboard_reason: '',
        resale_value: '',
        is_offboard: false,
        freight_rate: '',
        seating_capacity: '',
        bus_application: '',
        resale_scrap_date: '',
        resale_scrap_value: '',
        offboard_type: '',
        sim_status: '',
        sim_expiry_date: '',
        sim_activation_date: '',
        vehicle_lob_id: '',
        vehicle_lob_name: '',
        lob_name: '',
        lob_id: ''
    };
    this.vehicleCanDataService.addVehicleInVehicleMaster(this.reqObj).subscribe(response => {
    if (response) {
      if (response.length === 0 && response.length == null) {
          this.toaster.error('No record found', '', {
            timeOut: 5000
          });
        } else if  (response.status === 0) {
          this.visible = false;
          this.toaster.success('Vehicle Added Successfully', '', {
              timeOut: 5000
            });
          this.checkVehicleOnBoard.reset();
          this.addVehicleOnBoard.reset();
          this.checkVehicleOnBoard.get('vinNumber').setErrors(null);
          this.submitted = false;
          } else {
            this.toaster.error(response.message, '', {
              timeOut: 5000
            });
          }
        }
    });
}

CheckVinForFleetId() {
  if (this.checkVehicleOnBoard.invalid) {
    return;
  }
  if (this.checkVehicleOnBoard.value.vinNumber == null || this.checkVehicleOnBoard.value.vinNumber === '') {
    this.toaster.error('Vin required', '', {
      timeOut: 5000
    });
  } else {
      const checkdata = this.fleetIdData;
      const checkVin = Object.entries(checkdata);
      checkVin.forEach(element => {
        if (element[1] === this.selectedFleetid) {
          this.fleetidget = element[0];
        }
      });
      this.reqObj = {
          fleet_id: this.fleetidget,
          vehicle_id: '',
          vins: this.checkVehicleOnBoard.value.vinNumber
        };
      this.vehicleCanDataService.checkVehicleValidation(this.reqObj).subscribe(response => {
    if (response) {
      if (response.status === 0) {
        this.visible = true;
        this.toaster.success('Vehicle does not exists. Please add vehicle details', '', {
          timeOut: 5000
        });
      } else {
        this.visible = false;
        this.toaster.error(response.message, '', {
          timeOut: 5000
        });
      }
    }
  });
      this.getPage.sort = 'asc';
      this.getPage.field_name = 'branch_name';
      this.getPage.fleet_id = this.fleetidget;
      this.loadData(this.getPage);
      this.getLobdata();
}
}


getAddressStates() {
  this.vehicleListService.getAddressStateData()
    .subscribe((response) => {
      if (response.length > 0) {
        this.stateDropdown = response;
      } else {
        this.stateDropdown = this.getNAData();
      }
    },
      (error) => {
        this.stateDropdown = this.getNAData();
      }
    );
}

getStateData() {
  let state;

  if (typeof this.addVehicleOnBoard.value.state === 'object' && this.addVehicleOnBoard.value.state.code) {
    state = this.addVehicleOnBoard.value.state;
    return state;
  } else {
    const name = typeof this.addVehicleOnBoard.value.state === 'object' ?
      this.addVehicleOnBoard.value.state.name : this.addVehicleOnBoard.value.state;

    this.stateDropdown.forEach((state1) => {
      if (state1.name === name) {
        this.selectedStateCode = state1.code;
      }
    });
    state = {
      name,
      code: this.selectedStateCode
    };
    return state;

  }
}

getAddressDistricts() {
  const state = this.getStateData();
  this.vehicleListService.getAddressDistrictByState({
    state
  }).subscribe((response) => {
    this.addVehicleOnBoard.patchValue({
      district: '',
    });
    if (response.length > 0) {
      const data = [];
      response.map(item => {
        data.push({
          label: item,
          value: item
        });
      });
      this.districtDropdown = data;
    } else {
      this.districtDropdown = this.getNAData();
    }
  },
    (error) => {
      this.districtDropdown = this.getNAData();
    });
}


getAddressCities() {
  const state = this.getStateData();
  this.vehicleListService.getAddressCitiesByStateDistrict({
    district: this.addVehicleOnBoard.value.district,
    state
  }).subscribe((response) => {
    this.addVehicleOnBoard.patchValue({
      city: '',
    });
    if (response.length > 0) {
      const data = [];
      response.map(item => {
        data.push({
          label: item,
          value: item
        });
      });
      this.cityDropdown = data;
    } else {
      this.cityDropdown = this.getNAData();
    }
  },
    (error) => {
      this.cityDropdown = this.getNAData();
    });
}

loadData(requestObject) {
  this.vehicleCanDataService.getBranchId(requestObject)
    .subscribe(
      (response: any) => {
        if (response.results) {
          this.branchData = response.results;
        }
      },
      (error) => (
        console.log('error', error)
      )
    );
}

getLobdata() {
  this.checked = true;
  this.userLob = [];
  this.vehicleCanDataService.getVehicleLob(this.reqObj).subscribe(response => {
    if (response.status === 0 && response.hasOwnProperty('results') === true) {
      if (response.results.length <= 0) {

      } else {
        this.lobIdData = response.results;
        const checkLob = response.results;
        const arraycheckLob = Object.values(checkLob);
        arraycheckLob.forEach((element: any ) => {
          this.userLob.push({label: element.vehicle_lob_name, value: element.vehicle_lob_name});
        });
        this.lobData = this.userLob;
      }
    } else {
      this.toaster.error(response.message, '', {
        timeOut: 5000
      });
    }
});
}



// create bulk upload form
createBulkForm() {
  this.uploadFile = this.formBuilder.group({
    fileInput: ['', [Validators.required]],
  });
}

bulkUpload() {
  this.uploadDisplay = true;
  this.isFailedSheet = false;
  this.fileInput = false;
  this.isCorrectSheet = false;
  this.isDisabled = true;
  this.uploadFile.get('fileInput').reset();
  this.templateLink = this.s3LinkCvp + 'vehicle-master.xlsm';

  }

  // to upload file method
  uploadedFile(event) {
    this.filename = event.target.files[0].name;
    this.fileSize = this.configService.getBytes(event.target.files[0].size);
    this.fileUploaded = event.target.files[0];
    if (this.fileUploaded) {
      this.fileInput = true;
      this.isDisabled = false;
    }
    this.readExcel();
  }

  // method to read the uploaded excel
  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  // to clear the bulk uplaod form
  clearForm() {
    this.uploadFile.get('fileInput').reset('');
    this.isCorrectSheet = false;
    this.fileInput = false;
  }

  //  read json
  ReadAsJson() {
    this.submittedexcel = true;
    if (this.uploadFile.invalid) {
      this.uploadDisplay = true;
      return;
    } else {
      this.jsonData = XLSX.utils.sheet_to_json(this.worksheet, { raw: false });
      this.jsonData.forEach(ele => {
        ele.fleet_id = this.fleetidget;
      });
      console.log(this.jsonData, 'JSD');
      this.changedObj = this.keysToLowerCase(this.jsonData);
    }
    if (this.changedObj.length > 0) {
      this.isCorrectSheet = false;
      this.jsonKeyData = {
        data: this.changedObj,
      };
      this.returnJsonObject(this.jsonKeyData);
    } else {
      this.isCorrectSheet = true;
    }
  }

  //  to make the excel keys to lower case
  keysToLowerCase(obj) {
    obj = obj.map((item) => {
      //  tslint:disable-next-line: forin
      for (const key in item) {
        let upper = key.toLowerCase();
        //  replace all blank to '_'
        while (upper.indexOf(' ') > -1) {
          upper = upper.replace(' ', '_');
        }
        //  check if it already wasn't uppercase
        if (upper !== key) {
          item[upper] = item[key];
          delete item[key];
        }
      }
      return item;
    });
    return obj;
  }

  //  to store the excel data into an array
  returnJsonObject(event) {
    const newEvent = this.returnEvent(event);
    this.vehicleCanDataService.bulkUploadVehicleMaster(newEvent).subscribe(
      (response) => {
        if (response.status === 0) {
          this.uploadDisplay = false;
          this.toaster.success(response.message, '', {
            timeOut: 5000
          });
        } else {
          this.toaster.error(response.message, '', {
            timeOut: 5000
          });
        }
      },
      (
        error // Loader to wait for API Response
      ) => console.log('error', error)
    );
    this.excelData = newEvent;
    this.uploadDisplay = false;
    this.showbulkicon = true;
    this.clearForm();
  }

  // to return data after modified some records to above oject
  returnEvent(event) {
    if (event.hasOwnProperty('data')) {

      // event = {
      //   data: event.data
      // };
      const newEvent = event.data;
      return newEvent;
    }
  }

  //  onclick of cancel method in bulk upload form
  cancelBulkUploadForm() {
    this.uploadDisplay = false;
    this.clearForm();
  }
getNAData() {
  const data = [{
    label: 'NA',
    value: 'NA'
  }];
  return data;
}

get fc() {
  return this.checkVehicleOnBoard.controls;
}

get getformControls() {
    return this.addVehicleOnBoard.controls;
}

get f() { return this.uploadFile.controls; }

}
