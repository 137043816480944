import { environment } from "src/environments/environment";

const cloudFrontURL = environment.cloudFrontURL;

export const CALENDAR_ICON_SVG = `${cloudFrontURL}/tml-isp-assets/calendar-icon.svg`;
export const USER_PLACEHODER = `${cloudFrontURL}/tml-isp-assets/assets/user-placeholder.png`;
export const TM_LOGO = `${cloudFrontURL}/tml-isp-assets/assets/tm-logo-blue.svg`;
export const TATA_LOGO_BLACK = `${cloudFrontURL}/tml-isp-assets/assets/tata_logo_black.svg`;
export const BANNER_LOGO = `${cloudFrontURL}/tml-isp-assets/assets/banner_connecting_logo.svg`;
export const ASCENDING_SVG = `${cloudFrontURL}/tml-isp-assets/assets/ascending.svg`;
export const ASCENDING_GREY_SVG = `${cloudFrontURL}/tml-isp-assets/assets/ascending-grey.svg`;
export const DESCENDING_SVG = `${cloudFrontURL}/tml-isp-assets/assets/descending.svg`;
export const DESCENDING_GREY_SVG = `${cloudFrontURL}/tml-isp-assets/assets/descending-grey.svg`;
export const AIR_FILTER_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/air_filter_default.png`;
export const AIR_FILTER_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/air_filter_amber.png`;
export const ENGINE_LAMP_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_lamp_default.png`;
export const ENGINE_LAMP_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_lamp_amber.png`;
export const REGENERATION_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/dpf_regeneration_default.png`;
export const REGENERATION_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/dpf_regeneration_amber.png`;
export const DRIVER_WARNING_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/driver_warning_default.png`;
export const DRIVER_WARNING_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/driver_warning_amber.png`;
export const DEF_LOW_LEVEL_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/def_low_level_default.png`;
export const DEF_LOW_LEVEL_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/def_low_level_amber.png`;
export const MALFUNCTION_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/malfunction_default.png`;
export const MALFUNCTION_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/malfunction_amber.png`;
export const PARKING_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/parking_default.png`;
export const PARKING_RED = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/parking_red.png`;
export const ENGINE_STOP_RED = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_stop_red.png`;
export const ENGINE_STOP_RED_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_stop_default.png`;
export const WATER_IN_FUEL_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/water_in_fuel_default.png`;
export const WATER_IN_FUEL_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/water_in_fuel_amber.png`;
export const ENGINE_COOLANT_RED = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_coolant_red.png`;
export const ENGINE_COOLANT_DEFAULT = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/engine_coolant_default.png`;
export const SIGNAL_GREEN = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/signal_green.png`;
export const SIGNAL_AMBER = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/signal_amber.png`;
export const SIGNAL_RED = `${cloudFrontURL}/tml-isp-assets/assets/dashboard/signal_red.png`;
export const SPEEDOMETER_SVG = `${cloudFrontURL}/tml-isp-assets/assets/speedometer.png`;
export const EXCEL_SVG = `${cloudFrontURL}/tml-isp-assets/assets/excel_icon.svg`;
export const GROUP_6 = `${cloudFrontURL}/tml-isp-assets/assets/group-6.svg`;
export const BULK_UPLOAD_SVG = `${cloudFrontURL}/tml-isp-assets/assets/bulk_upload.png`;
export const LESS_THAN_ARROW_SVG = `${cloudFrontURL}/tml-isp-assets/assets/less-than-arrow.png`;
export const GREATER_THAN_ARROW_SVG = `${cloudFrontURL}/tml-isp-assets/assets/greater-than-arrow.png`;
