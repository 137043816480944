import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[InputGroup]'
})
export class InputGroupDirective {
  //used to select and get reference of inputgroup templates through @contentchildren that are projected into the query component 
  constructor(public templateRef: TemplateRef<any>) {}

}
