import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';

@Injectable()
export class ModifiedUrlInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let requestURL = request.url;
    const userRole = localStorage.getItem('role_type');

    // Update below array to include EDP user updated routes
    const excludedAPIPattern = ['/isp-service/','/user-onboarding/','/isp-vehicle-module/'];
    if (userRole === 'TML Internal' && !request.url.includes('/user-login')) {
      if (excludedAPIPattern.some((val) => requestURL.includes(val))) {
        const urlEndPointArray = requestURL.split('/');
        const apiKeywordIndex = urlEndPointArray.findIndex(val => val === 'api');
        urlEndPointArray.splice((apiKeywordIndex + 1), 0, 'edp');
        requestURL = urlEndPointArray.join('/');
      }
    }

    const updatedRequest = request.clone({
      url: requestURL
    });
    return next.handle(updatedRequest);
  }
}