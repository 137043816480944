export const checkPattern = {
    FULL_NAME: '[A-Za-z. ]*$',
    MOBILE: '[0-9]{10}',
    EMAIL_ID: '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}',
    VIN_LIST_EMAIL_ID:'^[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-_]{1,}',
    EMAIL_VALIDATION: /^([a-zA-Z][a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+)/,
    VIN_VALIDATION: /^[a-zA-Z0-9]{17}$/,
    REG_VALIDATION: /^[a-zA-Z0-9]{6,11}$/,
    VIN_VALID_NO: '[a-zA-Z0-9]{17}$',
    SMS_TEMPLATE_VALIDATION: /^[a-zA-Z0-9\n ]{1,200}$/,
    MOBILE_V:/^(\+\d{1,3}[- ]?)?\d{10}$/,
    EMAIL_MOBILE_VIN_OR:/^([a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-_]{1,})|^[a-zA-Z0-9]{17}$|^(\+\d{1,3}[- ]?)?\d{10}|^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
    PAN_NO: '^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$',
    NAME_WITH_SPACE: '[A-Za-z][A-Za-z ]+',
    NUMBER: '^[0-9]*$',
    NAME_WITH_COMMA: '[a-zA-Z0-9][a-zA-Z0-9, ]+',


    



}