import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlConstants } from 'src/app/shared/configurations/url-constants';


@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  addhttpOptions = this.configService.getAddEditHeaders();

  userPushNotification(userData): Observable<any> {
    return this.http.post(urlConstants.API_PUSH_NOTIFICATION, JSON.stringify(userData), this.addhttpOptions);
  }

}
