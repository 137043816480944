import { environment } from 'src/environments/environment';
import { S3_LINK, SKIN_URL, urlConstants } from './url-constants';

export const appLevelConstants = {
   
  // Login component
  DKEY : environment.DKEY,
  DIV : environment.DIV,
  LOGIN: 'Login',
  EMAIL: 'Email Id',
  DESC: 'Enter details registered with TATA Motors',
  PASSWORD: 'Password',
  TERMCONDITION: 'I agree to protect the confidentiality of the data I download from this portal.',
  PASSWORD_REQUIRED: 'Please provide a password.',
  EMAIL_REQUIRED: 'Email is required.',
  EMAIL_INVALID: 'Invalid Email',
  LOGIN_BUTTON: 'Login',
  //Event Data Component//
  TTTLE: "Event Data",
  TimeStampLevel: "Date and Time Range",
  EventName: "Event Name",

  // User  Activity 
  ACTION: "Get event logs",
  MODULE_NAME: "User Event Logs",
  // fuel & Odometer.
  TITLE: "Fuel & Odometer",
  DISTANCE: "Distance",
  DRIVER_NAME: "Driver Name",
  TRIP_STATUS: " Trip Status",
  FUEL_CONSUMED: "Fuel Consumed",
  DATE_TIMESTAMP: "Date and Timestamp",
  FUEL_LEVEL: "Fuel Level",
  DEF_LEVEL: "DEF LEVEL",
  REFUEL_TANK: "Refuel Tank",
  FUEL_THEFT: "Fuel Theft",
  SEARCH: "Search",
  DOWNLOAD: "Download",
  VIN_NUMBER: "VIN Number ",
  SELECT_DATA_TIME: "Select Date Time Range",
  SELECT_VEHICLE_TYPE: "Select Vehicle Type",
  VEHICLE_TYPE: [
		{name: 'SCV Vehicle', code: 'is_scv_vehicle'},
		{name: 'EV Vehicle', code: 'is_scv_ev_vehicle'},
		{name: 'Other', code: 'other'},
	],
  SPN_TYPE: "SPN TYPE",
  // Event Componet
  EVENT_DATA: 'Event Data',
  DATE_TIMESTAMP_LABEL: 'Date and Time Range',
  EVENT_NAME_LABEL: 'Event Label',
  // HEADER COMPONENT
  USER_ONBOARD_LABEL: 'User Onboard',
  EVENT_DATA_LABEL: 'Events Data',
  TELEMETRY_DATA_LABEL: 'Telemetry Data',
  VEHICLE_CAN_DATA_LABEL: 'Vehicle CAN Data',
  FUEL_AND_ODOMETER_DATA_LABEL: 'Fuel & Odometer',
  IGNITION_STATUS_LABEL: 'Ign Status',
  VEHICLE_LIST_LABEL: 'Vehicle List',
  FUEL_ALERT_LABEL: 'Fuel Alerts',
  SUBSCRIPTION:'Subscription',
  SENSORIZE:'Sensorize Status',
  VEHICLE_ON_BOARD_LABEL: 'Vehicle On Board',
  // LOGOUT POP-UP
  LOGOUT: 'Logout',
  YES: 'Yes',
  NO: 'No',
  //Vehicle Can Data
  Vehicle_Can_Data: "Vehicle CAN Data",
  spn_Number: "SPN Number",
  // Vehicle List
  FLEET_EMAIL_ID: "Fleet Owner User ID",
  // USER ONBOARD FORM
  USER_ONBOARD_TITLE: 'ONBOARD USER',
  USER_DETAILS_LABEL: 'User Details',
  FULL_NAME_LABEL: 'Full Name*',
  CONTACT_NUMBER_LABEL: 'Contact Number*',
  EMAIL_ID_LABEL: 'Email Id*',
  ROLE_LABEL: 'Role*',
  CANCEL_BUTTON: 'CANCEL',
  ADD_USER_BUTTON: 'ADD USER',
  ROLE_CHOICE: 'Select Role',
  ROLE: [
    {
      id: 1,
      userRole: 'Admin',
    },
    {
      id: 2,
      userRole: 'TML Internal',
    },
    {
      id: 3,
      userRole: 'Support Desk',
    },
    {
      id: 4,
      userRole: 'Dealer',
    },
    {
      id: 5,
      userRole: 'Accolade',
    },
    {
      id: 6,
      userRole: 'Electra EV',
    }],
  VINS: 'vins',
  FROM_DATETIME: 'from_datetime',
  TO_DATETIME: 'to_datetime',
  EVENT_TYPE:'event_type',
  ROLES: ["Admin", "TML Internal", "Support Desk", "Accolade", "Electra EV","Dealer"],
  IS_USER_TATA_EMPLOYEE: 'Is User a Tata Employee?',
  IS_USER_SUPPORT_EMPLOYEE: 'Is User a Support Desk Employee?',

  // USER PROFILE
  USER_PROFILE_TITLE: 'MY PROFILE',
  USER_NAME: 'Mukesh Patil',
  USER_ROLE: 'Administrator',
  ROLE_ID_LABEL: 'AD ID',
  ROLE_ID_DATA: 'abc123@gmail.com',
  CONTACT_DETAILS_TITLE: 'Contact Details',
  MOBILE_NO_LABEL: 'Mobile No:',
  MOBILE_NO: '8637263279',
  EMAIL_LABEL: 'Email Id:',
  EMAIL_ID: 'abc123@gmail.com',
  ACTIVE_USERS_TITLE: 'Active Users',
  INACTIVE_USERS_TITLE: 'Inactive Users',
  ADMIN_LABEL: 'Administrator:',
  ADMIN_COUNT: '10',
  TML_INTERNAL_LABEL: 'TML Internal:',
  TML_INTERNAL_COUNT: '100',
  SUPPORT_DESK_LABEL: 'Support Desk Team:',
  SUPPORT_DESK_COUNT: '100',
  AD_ID: 'AD ID',
  OID: 'OID',
  ENGINE_COOLANT: "High Engine Coolant Temperature Indicator",
  MOVING_MASS: "Moving Mass Estimation",
  ENGINE_AMBER: "Check Engine Lamp",
  ENGINE_RED_STOP: "Engine Red Stop Lamp",
  OBD_MALFUNCTION: "Malfunction Indication Lamp",
  PARKING_BRAKE: "Parking Brake ",
  DEF_LEVEL_INDICATOR: "DEF/Urea Low Level Indicator",
  DRIVER_WARNING: "SCR System Fault",
  WATER_IN_FUEL_INDICATOR: "Water In Fuel Indicator",
  DPF_REGENERATION: "DPF Regeneration Req",
  AIR_FILTER_CLOGGING: "Air filter clogging",
  GPS_SIGNAL: "GPS signal quality",
  DEF_TANK_LEVEL: "Diesel Exhaust Fluid Tank Level",
  ODOMETER: "ODOMETER",
  SPEEDOMETER: "SPEEDOMETER",
  FUEL: "FUEL",
  GPS_SIGNAL_QUALITY: "GPS SIGNAL QUALITY",
  KM_PER_L: "Km/L",
  KM_PER_KG: "Km/Kg",
  KM: "Km",
  L_PER_HR: "L/Hr",
  HR: "Hr",
  L: "L",
  NONE: "None",
  DOWN: "down",
  UP: 'up',

  VEHICLE_LIVE_STATUS: "VEHICLE LIVE STATUS",
  TRIP_DETAILS: "TRIP DETAILS",
  GEOFENCE_ADDED: "GEOFENCE ADDED",
  VEHICLE_DETAILS: "VEHICLE DETAILS",
  CURRENT_LOCATION: "CURRENT LOCATION",
  TRIP_JOURNEY: "Trip Journey",
  START_DATE: "Start Date",
  EXPECTED_END_DATE: "Expected End Date",
  // DRIVER_NAME: "Driver Name",
  DRIVER_MOBILE_NUMBER: "Driver Mobile Number",
  CONSIGNOR_NAME: "Consignor Name",
  CONSIGNOR_MOBILE_NUMBER: "Consignor Mobile Number",
  CONSIGNEE_NAME: "Consignee Name",
  CONSIGNEE_MOBILE_NUMBER: "Consignee Mobile Number",
  // VIN_NUMBER: "VIN Number",
  REGISTRATION_NUM: "Registration Number",
  REGISTRATION_NUMBER_FUEL: 'Reg/VIN No',
  NOTIFICATION_REMINDER_TYPE: 'Reminder Type',
  REMINDER_LIST_REPORTED_AT: 'Reported At',
  REGISTRATION_NUMBER_REMINDER: "Reg No.",
  NOTIFICATION_REMINDER_NAME: "Reminder Name",
  MOVING: "Moving",
  STOPPED: "Stopped",
  IDLING: "Idling",
  DELAYED: "Delayed",
  OFFLINE: "Offline",
  VEHCILE_STATUS: "Status",
  LIVE_INCIDENCE_ODOMETER: "Odometer",
  LAST_FUEL_FILLED: "Last Fuel Filled",
  VIN_NO: "VIN No.",
  NO_RECORDS_FOUND: "No Records Found",
  NO_INVOICE_FOUND: "No Invoice Generated",


  // Dashboard card titles
  TITLE_LIVE_INCIDENTS: "TOTAL INCIDENTS",
  TITLE_OPEN_ISSUES: "OPEN ISSUES",
  TITLE_VEHICLE_STATUS: "VEHICLE STATUS",
  TITLE_LIVE_FLEET_STATUS: "LIVE FLEET STATUS",
  TITLE_FLEET_UTILIZATION: "FLEET OVERVIEW",
  TITLE_NOTIFICATION: "NOTIFICATIONS",
  SUBTITLE_NOTIFICATION:
    "Filter Notifications on the basis of driver, vehicle and fuel theft.",
  TITLE_DRIVER_SCORE_LEADERBOARD: "DRIVER SCORE LEADERBOARD",
  TITLE_FUEL_FILLED: "FUEL FILLED",
  TITLE_MONTHLY_AVERAGE_TRIP_COST: "MONTHLY AVERAGE TRIP COST",
  TITLE_VEHICLE_UTILIZATION: "VEHICLE UTILIZATION",
  TITLE_VEHICLE_SERVICE_STATUS: "VEHICLE SERVICE STATUS",
  TITLE_DRIVER_UTILIZATION: "DRIVER UTILIZATION",
  TITLE_OVERALL_COST: "OVERALL TRIP AND VEHICLE COST",
  TITLE_DRIVING_BEHAVIOUR: "DRIVING BEHAVIOUR",
  SUBTITLE_DRIVING_BEHAVIOUR:
    "Driving behaviour gives count of each event occured during running vehicle. Events are Harsh Acceleration, Harsh Braking, Rash Turning, idling, Coasting & Stoppage",
  TITLE_SEARCH_LIVE_FLEET: "Search Live Fleet",
  TITLE_SEARCH_MANUFACTURE_NAME: "Search Manufacture Name",
  TITLE_SEARCH_TYRE_BRAND_NAME: "Search Tyre Brand Name",
  TITLE_LIVE_FLEET: "LIVE FLEET",
  FILTER: "Filter",
  APPLICATION_TYPE: "Application Type",
  LOB_TYPE: "LOB",
  NO_TRIP_COMPLETED: "No. Of Trip Completed",
  END_DATE_LABEL: "End Date",
  START_DATE_LABEL: "Start Date",
  ISSUES: "Issues",
  IN_LAST_24_HOURS: "In last 24 hours",
  UNATTENDED: "Unattended",
  ATTENDED: " Attended",
  ESTIMATED_DISTANCE_OF_TRIP: "Estimated distance of trip",
  TRIP_DISTANCE_COVERED: "Trip distance covered",
  CONSIGNMENT_WEIGHT: "Consignment weight",
  CREATED_BY: "Created By",
  FAULT_MESSAGE: "Fault Message",
  NO_DATA_AVAILABLE: "No Data Available",
  TOTAL_DISTANCE_OF_TRIP: "Total distance of trip",
  FLEET_MANAGER_NAME: "Fleet Manager Name",
  FLEET_UTILIZATION: "Fleet Utilization",
  MODEL: "Model",
  LINE_OF_BUSINESS: "Line Of Business",
  PRODUCT_LINE: "Product Line",
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
  LAST_UPDATED_AT: "Last Update At",
  LOCATION_NOT_FOUND: "Location Not Found",
  LIVE_LOCATIONS: "Live Locations:",
  CONSIGNEE_CIN_NUMBER: "Consignee CIN Number",
  CONSIGNEE_FAX_NUMBER: "Consignee Fax Number",
  vehicleInService: "Vehicle In Service",
  vehicleBreakdown: "Breakdown Count",
  TRIP_CARDS: "Trip Cards",
  ADD_DOCUMENTS: "Add Documents",
  ADD_MILESTONE: "Add Milestone",
  VIEW_ADDED_MILESTONE: "View Added Milestone",
  UPDATE_MILESTONE: "Update Milestone",
  ACTUAL_LOCATION_START: "Actual Start",
  ACTUAL_LOCATION_END: "Actual End",
  ESTIMATED_REACHING_DATE: "Estimated Reaching Date",
  ESTIMATED_REACHING_TIME: "Estimated Reaching Time",
  VIEW_ADDED_MILESTONES: "View Added Milestones",

  //DIC
  TRIP_A_FUEL_EFFICIENCY: "Trip A FE",
  TRIP_B_FUEL_EFFICIENCY: "Trip B FE",

  //Blocked Fleet ID Management
  GET_USER_STATUS_MODULE_NAME: "Keyclock User",
  GET_USER_STATUS_ACTION: "Get User Block Status",
  UNBLOCK_USER_MODULE_NAME: "Keyclock User",
  UNBLOCK_USER_ACTION: "Unblock User",


  //Logging
  LOGIN_ACTION_GET_TOKEN: "Login(Get token)",
  LOGIN_MODULE_NAME: "Login",
  GET_USER_COUNT_ACTION: "Get active users count",
  MY_PROFILE_MODULE_NAME: "My Profile",
  ONBOARD_USER_MODULE_NAME: "User Onboarding",
  GET_USER_LIST: "Get User List",
  SEARCH_USER: "Search User",
  UPDATE_USER_STATUS: "Update User Status",
  USER_MANAGEMENT_MODULE_NAME: "User Access Management",
  ADD_USER_ACTION: "Add user",
  GET_EVENT_DATA_ACTION: "Get events data",
  GET_FUEL_DATA_ACTION: "Get Vehicle Fuel Data",
  EVENT_DATA_MODULE_NAME: "Events Data",
  FUEL_ALERTS_MODULE_NAME: "Fuel Alerts",
  DOWNLOAD_EVENT_DATA_ACTION: "Download Event Data",
  GET_VEHICLE_FUEL_ALERT_DATA_ACTION: "Get Vehicle Fuel Alert Data",
  DOWNLOAD_VEHICLE_FUEL_ALERT_DATA_ACTION: "Download Vehicle Fuel Alert Data",
  GET_TELEMETRY_DATA_ACTION: "Get vehicle telemetry data",
  TELEMETRY_DATA_MODULE_NAME: "Telemetry Data",
  DOWNLOAD_TELEMETRY_DATA_ACTION: "Download Telemetry Data",
  GET_CAN_DATA_ACTION: "Get vehicle can data",
  VEHICLE_CAN_DATA_MODULE_NAME: "Vehicle CAN Data",
  DOWNLOAD_CAN_DATA_ACTION: "Download Vehicle CAN Data",
  GET_FUEL_AND_ODOMETER_DATA_ACTION: "Get vehicle fuel and odometer data",
  FUEL_AND_ODOMETER_DATA_MODULE_NAME: "Fuel and Odometer",
  DOWNLOAD_FUEL_AND_ODOMETER_DATA_ACTION: "Download Vehicle Fuel and Odometer Data",
  DOWNLOAD_IGNITION_STATUS_DATA_ACTION: "Download Ignition Data",
  IGNITION_STATUS_DATA_MODULE_NAME: "Ignition Status",
  GET_IGNITION_STATUS_DATA_ACTION: "Get ignition status data",
  GET_VEHICLE_LIST_DATA_ACTION: "Get vehicle list data",
  VEHICLE_LIST_DATA_MODULE_NAME: "Vehicle List",
  DOWNLOAD_VEHICLE_LIST_DATA_ACTION: "Download vehicle list",
  LOGOUT_ACTION: "Logout User",
  LOGOUT_MODULE_NAME: "Logout",
  TEXT_FOR_COPYRIGHT: "This document is private and only for use inside TATA Motors",
  WATERMARK_LABEL: localStorage.getItem('user_id'),

  //Survey 
  HOME_SCREEN:'Home Screen',
  CREATE_SURVEY:'Create Survey',
  START_DATE_AND_END_DATE:'Start date and End date',
  APPLICABLE_STATE:'Applicable State',
  SURVEY_FOR:'Survey For',
  DUPLICATE:'Duplicate',
  LAUNCH:'Launch',
  PREVIEW:'Preview',
  SAVE:'Save',
  ADD_OPTION:'Add Option',
  MANDATORY:'Mandatory',
  REQUIRED:'required',
  FREE_TEXT_KEY:'freeText',
  SINGLE_SELECTION_KEY:'singleSelection',
  MULTIPLE_SELECTION_KEY:'multipleSelection',
  DROPDOWN_SELECTION_KEY:'dropdownSelection',
  RATINGQUESTION_KEY:'ratingQuestion',
  FREE_TEXT:'Free Text',
  SINGLE_CHOICE:'Single Choice',
  MULTIPLE_CHOICE:'Multiple Choice',
  DROPDOWN:'Dropdown',
  RATING:'Rating',
  QUESTION:'question',
  QUESTION_ID:'q1',
  DEFAULT_QUESTION:'Write a question here.',
  DEFAULT_RATING_QUESTION:'Please rate your Experience with the Fleet Edge.',
  DEFAULT_SINGLE_QUESTION:'Single Selection Question',
  DEFAULT_MULTIPLE_SELECTION_QUESTION:'Multiple selection Question',
  DEFAULT_DROPDOWN_QUESTION:'dropdown selection Question',
  DEFAULT_OPTION_VALUE:'Write a option',
  LAUNCH_IN_LOWERCASE:'launch',
  SAVE_IN_LOWERCASE:'save',
  SURVEY_LABEL:'Survey',
  SURVEY_LIST:'Survey List',
  SURVEY_ID:'Survey_ID',
  DESCRIPTION:'When Customer Download all the column get download including question along with their reponse from each customer',
  DOWNLOAD_BUTTON:'Download',
  VIEW:'View',
  LOADING_TEXT: 'Loading ....',
  DATE_FILTER:'Enter Date Range...',

  //Survey-svg
  CALENDAR_ICON:'../../../../assets/images/survey/calendar-icon.svg',
  SEARCH_ICON:'../../../../assets/images/survey/search-icon.svg',
  UNCHECKED_ICON :'../../../../assets/images/survey/Unchecked-icon.svg',
  CHECKED_ICON:'../../../../assets/images/survey/checked-icon.svg',
  DOWN_ARROW_ICON:'../../../../assets/images/survey/down-arrow-icon.svg',
  DELETE_ICON:'../../../../assets/images/survey/delete.svg',
  ADD_ICON:'../../../../assets/images/survey/add-icon.svg',
  SURVEY_WHITE_ICON:'../../../assets/images/survey/survey-white-icon.svg',
  SURVEY_ICON:'../../../assets/images/survey/survey-icon.svg',
  EDIT_ICON:'../../../assets/images/survey/edit.svg',
  DOWNLOAD_ICON:'../../../assets/images/survey/download.svg',
  VIEW_ICON:'../../../assets/images/survey/view.svg',
  DELETE:'../../../assets/images/survey/delete-survey.svg',

  //survey-array
  surveyUserName:[
    { survey_user_id: 'Fleet Owner', survey_user_name: 'Fleet Owner' },
    { survey_user_id: 'Fleet Manager', survey_user_name: 'Fleet Manager' },
    { survey_user_id: 'Driver App', survey_user_name: 'Driver App' },
  ],

  //push notificaton
  PUSH_NOTIFICATION_TITLE: 'NOTIFICATION',
  PUSH_NOTFICATION_DETAILS_LABEL: 'Notification Details',
  NOTIFICATION_AS_PER: 'Send Notification As Per',
  NOTIFICATION_USER_TYPE: 'User type',
  NOTIFICATION_SELECTIVE_USER: 'Selective User',
  USER_TYPE_LABEL: 'User Type*',
  USER_TYPE: [
    {
      id: 1,
      userType: 'Fleet Owner',
    },
    {
      id: 2,
      userType: 'Fleet Manager',
    },
    {
      id: 3,
      userType: 'Account Manager',
    },
    // {
    //   id: 4,
    //   userType: 'Driver',
    // },
    {
      id: 4,
      userType: 'All',
    }
  ],
  NOTIFICATION_TITLE_LABEL: 'Notification Title*',
  NOTIFICATION_BODY_LABEL: 'Notification Body*',
  NOTIFICATION_BODY_NOTE: `Note : If you want to specify name of the user in notification
  body then use "{name}" token.`,
  SELECT_NOTIFICATION_TYPE_LABEL: 'Select Notification Type*',
  SELECT_NOTIFICATION_TYPES: [
    { name: 'SMS', value: 'sms' },
    { name: 'Push', value: 'push' },
    { name: 'Email', value: 'email' }
  ],
  SUBMIT_BUTTON: 'Submit',
  HTML_VARIABLES_BULK_UPLOAD: {
    bulkUpload: '- Bulk Upload',
    uploadLable: 'Upload excel file of',
    list: 'List',
    download: 'Download Excel Template',
    downloadLinkLable: 'Download the excel template',
    click: 'Click here to see errors',
    errorToolTip: 'Select branch from list',
    SucessToolTip: 'Download bulk upload template from here',
    exportToolTip: 'Download data to update',
    selectBranch: 'Please Select branch',
    blanckSheetError: 'Please provide appropriate data into the excel sheet',
    messageForUI:
      ' After downloading, fill the template document with correct multiple information and upload it.',
    remove: 'Remove',
    uploading: 'Uploading',
    uploaded: 'Uploaded',

  },
  S3_link_bulk_upload: S3_LINK,
  skinUrl: SKIN_URL,
  GET_STATE_URL: SKIN_URL + '/api/states/',
  GET_DISTRICT_URL: SKIN_URL + '/api/districts/',
  GET_CITY_URL: SKIN_URL + '/api/cities/',

  DropdownEvent: [
    {
      value: "",
      view: "None"
    },
    {
      value: "EngineStopEvent",
      view: "EngineStopEvent"
    },
    {
      value: "IgnitionOffEvent",
      view: "IgnitionOffEvent"
    },
    {
      value: "cvaccldvehicleidlingalert",
      view: "cvaccldvehicleidlingalert"
    },
    {
      value: "EngineStartEvent",
      view: "EngineStartEvent"
    },
    {
      value: "IgnitionOnEvent",
      view: "IgnitionOnEvent"
    },
    {
      value: "cvaccldstoppagealert",
      view: "cvaccldstoppagealert"
    },
    {
      value: "DigitalInputEvent",
      view: "DigitalInputEvent"
    },
    {
      value: "cvaccldcoastingalert",
      view: "cvaccldcoastingalert"
    },
    {
      value: "AnalogInputEvent1",
      view: "AnalogInputEvent1"
    },
    {
      value: "HarshAccelerationEvent",
      view: "HarshAccelerationEvent"
    },
    {
      value: "cvaccldcoastingignitionoffalert",
      view: "cvaccldcoastingignitionoffalert"
    },
    {
      value: "BackupBatteryLowEvent",
      view: "BackupBatteryLowEvent"
    },
    {
      value: "cvaccldstoppageloadonbatteryalert",
      view: "cvaccldstoppageloadonbatteryalert"
    },
    {
      value: "OverSpeedEvent",
      view: "OverSpeedEvent"
    },
    {
      value: "RashTurningEvent",
      view: "RashTurningEvent"
    },
    {
      value: "VehicleBatteryReconnectEvent",
      view: "VehicleBatteryReconnectEvent"
    },
    {
      value: "VehicleBatteryCutEvent",
      view: "VehicleBatteryCutEvent"
    },
    {
      value: "PanicSosEvent",
      view: "PanicSosEvent"
    },
    {
      value: "cvbschstoppagealert",
      view: "cvbschstoppagealert"
    },
    {
      value: "cvbschstoppageloadonbatteryalert",
      view: "cvbschstoppageloadonbatteryalert"
    },
    {
      value: "cvbschvehicleidlingalert",
      view: "cvbschvehicleidlingalert"
    },
    {
      value: "cvbschcoastingignitiononalert",
      view: "cvbschcoastingignitiononalert"
    },
    {
      value: "cvaccldcoastingignitiononalert",
      view: "cvaccldcoastingignitiononalert"
    },
    {
      value: "tpmsalerteventaccld",
      view: "tpmsalerteventaccld"
    },
    {
      value: "GearShiftEvent",
      view: "GearShiftEvent"
    },
    {
      value: "VehBatteryCutEvent",
      view: "VehBatteryCutEvent"
    },
    {
      value: "HarshBrakeEvent",
      view: "HarshBrakeEvent"
    },
    {
      value: "Rash Turning",
      view: "Rash Turning"
    },
    {
      value: "Ignition ON",
      view: "Ignition ON"
    },
    {
      value: "Ignition Off",
      view: "Ignition Off"
    },
    {
      value: "Device Tamper Event",
      view: "Device Tamper Event"
    },
    {
      value: "VehBatteryLowEvent",
      view: "VehBatteryLowEvent"
    },
    {
      value: "BlowerOnOffEvent",
      view: "BlowerOnOffEvent"
    },
    {
      value: "FotaInstallationInformationEvent",
      view: "FotaInstallationInformationEvent"
    },
    {
      value: "FotaStateEvent",
      view: "FotaStateEvent"
    },
    {
      value: "DMSVideoStreamFailedEvent",
      view: "DMSVideoStreamFailedEvent"
    },
    {
      value: "DMSEvents",
      view: "DMSEvents"
    },
    {
      value: "InitiateFOTAEvent",
      view: "InitiateFOTAEvent"
    },
    {
      value: "CumminsCSUStateEvent",
      view: "CumminsCSUStateEvent"
    },
    {
      value: "CumminsCSUInstallationInformationEvent",
      view: "CumminsCSUInstallationInformationEvent"
    },
    {
      value: "AirConditioningStartEvent",
      view: "AirConditioningStartEvent"
    },
    {
      value: "AirConditioningStopEvent",
      view: "AirConditioningStopEvent"
    },
    {
      value: "CumminsSeedTokenRequestEvent",
      view: "CumminsSeedTokenRequestEvent"
    },
    {
      value: "TMLCDConfigTransferStatusEvent",
      view: "TMLCDConfigTransferStatusEvent"
    },
    {
      value: "TCUDisconnectionEvent",
      view: "TCUDisconnectionEvent"
    },


    // {
    //   id: 1,
    //   userEvent: 'Empty',
    // },
    // {
    //   id: 2,
    //   userEvent: 'OFF',
    // },
    // {
    //   id: 3,
    //   userEvent: 'ON',
    // }, {
    //   id: 3,
    //   userEvent: 'ON OFF',
    // }
  ],
};

export const mapDarkTheme = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#181818",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#1b1b1b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2c2c2c",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8a8a8a",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#373737",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#3c3c3c",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#4e4e4e",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#3d3d3d",
      },
    ],
  },

];


