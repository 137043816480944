import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { urlConstants } from 'src/app/shared/configurations/url-constants';
import { ConfigService } from 'src/app/config.service';
import { TitleCasePipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class VehicleCanDataService {

  constructor(private http: HttpClient,
    private titlecasePipe: TitleCasePipe,
  private configService: ConfigService) { }

  httpOptions = this.configService.getApplicationHeaders();
  addhttpOptions = this.configService.getAddEditHeaders();

  vehicleCanDataApi(userData): Observable<any> {
    return this.http.post(urlConstants.VEHICLE_HISTORICAL_CAN_DATA, JSON.stringify(userData), this.getAddEditHeaders());
  }

  getVehicleStatus(data): Observable<any>{
    return this.http.post(urlConstants.VEHICLE_STATUS,JSON.stringify(data),this.getAddEditHeaders())
  }

  getKeyCloakToken() {
    return 'Bearer ' + localStorage.getItem('auth_token');
  }

  getAddEditHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: this.getKeyCloakToken()
      })
    };
  }

  getVehichleVinsByCrm(data): Observable<any>{
    return this.http.post(urlConstants.GET_VEHICLE_VINS_BY_CRM,JSON.stringify(data),this.getAddEditHeaders())
  }


  addVehicleInVehicleMaster(data): Observable<any>{
    return this.http.post(urlConstants.ADD_VEHICLE_MASTER,JSON.stringify(data),this.getAddEditHeaders())
  }

  bulkUploadVehicleMaster(data): Observable<any>{
    return this.http.post(urlConstants.BULK_VEHICLE_MASTER,JSON.stringify(data),this.getAddEditHeaders())
  }

  keyOldNew(newObject: any, oldKey: any, newKey: any) {
    delete Object.assign(newObject, {
      [newKey]: this.titlecasePipe.transform(newObject[oldKey])
    }
    )[oldKey];
    return newObject;
  }

  deleteKeys(newObject: any, keyName: any) {
    delete newObject[keyName];
    return newObject;
  }

  checkVehicleValidation(data): Observable<any>{
    return this.http.post(urlConstants.CHECK_VEHICLE_VALIDATION,JSON.stringify(data),this.getAddEditHeaders())
  }

  getUserId(data): Observable<any>{
    return this.http.post(urlConstants.GET_USER_ID,JSON.stringify(data),this.getAddEditHeaders())
  }

  getBranchId(data): Observable<any>{
    return this.http.post(urlConstants.GET_BRANCH,JSON.stringify(data),this.getAddEditHeaders())
  }

  getVehicleLob(data): Observable<any>{
    return this.http.post(urlConstants.GET_LOB_DROPDOWN,JSON.stringify(data),this.getAddEditHeaders())
  }

  getVehicleFuelTrend(data): Observable<any>{
    return this.http.post(urlConstants.GET_VEHICLE_FUEL_TREND,JSON.stringify(data),this.getAddEditHeaders())
  }

}

