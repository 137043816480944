import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { urlConstants } from '@shared/configurations/url-constants';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAccessService {

  constructor(private http: HttpClient,
    private configService: ConfigService) { }

    getAdminAccessStatus(adminInfo : any):  Observable<any>{
      return this.http.post(urlConstants.ADMIN_ACCESS_STATUS,adminInfo,this.configService.getAddEditHeaders())

    }

    setAdminAccessStatus(adminInfo : any): Observable<any>{
      return this.http.post(urlConstants.ADMIN_ACCESS_SET,adminInfo,this.configService.getAddEditHeaders())
    }
}
