// last changes on: 05-03-2024 | By: Furqan Ali

export const environmentKeys = {
  dev: {
    GOOGLE_MAPS_API_KEY: 'AIzaSyDIpShQ6gDdEonzrak7SvNCx88BtMtimNQ',
    GEOCODE_KEY : "AIzaSyDyahVcZtWDI3MQnOWusqbTmgzubgAEob8",
    GEOCODE_URL: 'https://maps.googleapis.com/maps/api/geocode/',
    DKEY : "cGx1dG9pc25vdGFjb21ldA==",
    DIV : "ttlshiwwuruawshl",
    cloudFrontURL: 'https://d1mk50hnhgdjj6.cloudfront.net/dev',
    firebaseConfig : {
      apiKey: "AIzaSyCnTmo90F-UkW2Cm__bMDq2WnualruVPVI",
      authDomain: "cvp-analytics.firebaseapp.com",
      databaseURL: "https://cvp-analytics.firebaseio.com",
      projectId: "cvp-analytics",
      storageBucket: "cvp-analytics.appspot.com",
      messagingSenderId: "864104640164",
      appId: "1:864104640164:web:1e3d428ed258064bce0031",
      measurementId: "G-34M8W6WXWZ"
    },
    BASIC_AUTH : 'Basic Y3ZwLXVzZXItMTpwbHV0b2lzbm90YXBsYW5ldA'
  },
  qa: {
    GOOGLE_MAPS_API_KEY: 'AIzaSyDIpShQ6gDdEonzrak7SvNCx88BtMtimNQ',
      GEOCODE_KEY : "AIzaSyDyahVcZtWDI3MQnOWusqbTmgzubgAEob8",
      GEOCODE_URL: 'https://maps.googleapis.com/maps/api/geocode/',
      DKEY : "cGx1dG9pc25vdGFjb21ldA==",
      DIV : "ttlshiwwuruawshl",
      cloudFrontURL: 'https://d1mk50hnhgdjj6.cloudfront.net/qa',
      firebaseConfig : {
        apiKey: "AIzaSyCnTmo90F-UkW2Cm__bMDq2WnualruVPVI",
        authDomain: "cvp-analytics.firebaseapp.com",
        databaseURL: "https://cvp-analytics.firebaseio.com",
        projectId: "cvp-analytics",
        storageBucket: "cvp-analytics.appspot.com",
        messagingSenderId: "864104640164",
        appId: "1:864104640164:web:1e3d428ed258064bce0031",
        measurementId: "G-34M8W6WXWZ"
      },
      BASIC_AUTH : 'Basic Y3ZwLXVzZXItMTpwbHV0b2lzbm90YXBsYW5ldA'
  },
  staging: {
   
  },
  production:{
    GOOGLE_MAPS_API_KEY: 'AIzaSyDIpShQ6gDdEonzrak7SvNCx88BtMtimNQ',
    GEOCODE_KEY : "AIzaSyDyahVcZtWDI3MQnOWusqbTmgzubgAEob8",
    GEOCODE_URL: 'https://maps.googleapis.com/maps/api/geocode/',
    DKEY : "cGx1dG9pc25vdGFjb21ldA==",
    DIV : "ttlshiwwuruawshl",
    cloudFrontURL: 'https://d1mk50hnhgdjj6.cloudfront.net/production',
    firebaseConfig : {
      apiKey: "AIzaSyCnTmo90F-UkW2Cm__bMDq2WnualruVPVI",
      authDomain: "cvp-analytics.firebaseapp.com",
      databaseURL: "https://cvp-analytics.firebaseio.com",
      projectId: "cvp-analytics",
      storageBucket: "cvp-analytics.appspot.com",
      messagingSenderId: "864104640164",
      appId: "1:864104640164:web:1e3d428ed258064bce0031",
      measurementId: "G-34M8W6WXWZ"
    },
    BASIC_AUTH : 'Basic Y3ZwLXVzZXItMTpwbHV0b2lzbm90YXBsYW5ldA'
    
  },
};
